import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getStorage } from "firebase/storage";

import {
  landscapesBucketName,
  portraitsBucketName,
  iconsBucketName,
} from "./config";

const firebaseApp = initializeApp({
  appId: process.env.REACT_APP_APP_ID,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  apiKey: process.env.REACT_APP_API_KEY,
  projectId: process.env.REACT_APP_PROJECT_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
// TODO: uncomment when all emulator stuff is ready
//if (window.location.hostname === "localhost") {
//  db.settings({
//    host: "localhost:8080",
//    ssl: false
//  });
//}
export const portraitsStorage = getStorage(
  firebaseApp,
  `gs://${portraitsBucketName}`
);
export const landscapesStorage = getStorage(
  firebaseApp,
  `gs://${landscapesBucketName}`
);
export const iconsStorage = getStorage(firebaseApp, `gs://${iconsBucketName}`);
export const perf = getPerformance(firebaseApp);

export const analytics = getAnalytics(firebaseApp);

export const firestoreErrors = {
  INSUFFICIENT_PERMISSIONS: "permission-denied",
};

export const firebase = firebaseApp;
