import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import { AppLoading } from "./components/app/AppLoading";
import { getRedirectTo } from "./utils";
import { UserContext } from "./contexts/user";

export const UnAuthenticatedRoute = (props: any) => {
  const [user, loading] = useContext(UserContext);
  if (loading) return <AppLoading />;
  if (!user) {
    return <Route {...props} />;
  }
  const redirectTo = getRedirectTo();
  return <Redirect to={redirectTo || accountRoutes.index} />;
};

export const AuthenticatedRoute = (props: any) => {
  const [user, loading] = useContext(UserContext);
  if (loading) return <AppLoading />;
  if (user) {
    return <Route {...props} />;
  }
  const path = props.location.pathname;
  return <Redirect to={`${authRoutes.login}?redirectTo=${path}`} />;
};

export const landingRoutes = {
  index: "/",
  privacy: "/privacy",
  terms: "/terms",
};

export const authRoutes = {
  index: "/_/auth",
  login: `/_/auth/login`,
  confirm: `/_/auth/confirm`,
  emailSent: `/_/auth/emailSent`,
  forgot: `/_/auth/forgot`,
  reset: `/_/auth/reset`,
  action: `/_/auth/action`,
  verify: `/_/auth/verify`,
};

export const creatorRoutes = {
  index: "/_/creator/:step",
  title: "/_/creator/title",
  date: "/_/creator/date",
  images: "/_/creator/images",
  icon: "/_/creator/icon",
  name: "/_/creator/name",
  summary: "/_/creator/summary",
  creating: "/_/creator/creating",
  done: "/_/creator/done",
};

export const accountRoutes = {
  index: "/_/account",
  apps: "/_/account/apps",
  upgrade: "/_/account/upgrade",
};
