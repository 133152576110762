// Anything changed in this component MUST be change in ./public/index.html

import styled, { keyframes, css } from "styled-components";

const heartbeat = keyframes`
0% {
  transform: scale( .75 );
}
20% {
  transform: scale( 1 );
}
40% {
  transform: scale( .75 );
}
60% {
  transform: scale( 1 );
}
80% {
  transform: scale( .75 );
}
100% {
  transform: scale( .75 );
}
`;

interface HeartProps {
  baseSize?: number;
  animated?: boolean;
}

const defaultBaseSize = 50;

export const Heart = styled.div<HeartProps>`
  position: relative;
  width: ${({ baseSize = defaultBaseSize }) => `${baseSize * 2}px`};
  height: ${({ baseSize = defaultBaseSize }) => `${baseSize * 2 * 0.9}px`};
  animation: ${({ animated }) =>
    animated &&
    css`
      ${heartbeat} 3s infinite
    `};
  margin: 0 auto;
  &:before,
  &:after {
    position: absolute;
    content: "";
    left: ${({ baseSize = defaultBaseSize }) => `${baseSize}px`};
    top: 0;
    width: ${({ baseSize = defaultBaseSize }) => `${baseSize}px`};
    height: ${({ baseSize = defaultBaseSize }) => `${(baseSize / 50) * 80}px`};
    background: #fff;
    -moz-border-radius: 50px 50px 0 0;
    border-radius: 50px 50px 0 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0 100%;
    -moz-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    -o-transform-origin: 0 100%;
    transform-origin: 0 100%;
  }
  &:after {
    left: 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    -o-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
`;
