// Anything changed in this component MUST be change in ./public/index.html
import React from "react";
import styled from "styled-components";
import { colors } from "@lovers14/common";

import { Heart } from "../common/Heart";

interface AppLoadingProps {
  inApp?: boolean;
}

const Container = styled.div<AppLoadingProps>`
  background-color: ${colors.secondary};
  position: ${({ inApp }) => (inApp ? "relative" : "absolute")};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

export const AppLoading = ({ inApp }: AppLoadingProps) => {
  return (
    <Container {...{ inApp }}>
      <Heart animated={true} />
    </Container>
  );
};
