import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { GlobalStyle } from "@lovers14/common";
import styled from "styled-components";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { AppLoading } from "./components/app/AppLoading";
import { useScrollToTop } from "./hooks/useScrollToTop";
import "./i18n";
import {
  landingRoutes,
  authRoutes,
  creatorRoutes,
  accountRoutes,
  AuthenticatedRoute,
} from "./routes";
import { AuthProvider } from "./contexts/user";
import reportWebVitals from "./reportWebVitals";

const EnhancedSwitch: React.FC = ({ children }) => {
  useScrollToTop();
  return <Switch>{children}</Switch>;
};

const Container = styled.div`
  height: 100%;
`;

const createLazyRoute = (path: string, component: any) => {
  return React.lazy(() =>
    import(`./components/${path}/${component}`).then((module) => ({
      default: module[component],
    }))
  );
};
const LazyLanding = createLazyRoute("landing", "Landing");
const LazyPrivacy = createLazyRoute("landing", "Privacy");
const LazyTerms = createLazyRoute("landing", "Terms");
const LazyAuth = createLazyRoute("auth", "Auth");
const LazyCreator = createLazyRoute("creator", "Creator");
const LazyAccount = createLazyRoute("account", "Account");
const LazyApp = createLazyRoute("app", "App");

const Index = () => {
  return (
    <Container data-location={document.location.pathname}>
      <GlobalStyle />
      <Suspense fallback={<AppLoading />}>
        <BrowserRouter>
          <AuthProvider>
            <EnhancedSwitch>
              <Route exact path={landingRoutes.index} component={LazyLanding} />
              <Route path={landingRoutes.privacy} component={LazyPrivacy} />
              <Route path={landingRoutes.terms} component={LazyTerms} />
              <Route path={authRoutes.index} component={LazyAuth} />
              <AuthenticatedRoute
                path={creatorRoutes.index}
                component={LazyCreator}
              />
              <AuthenticatedRoute
                path={accountRoutes.index}
                component={LazyAccount}
              />
              <Route path="/:id" component={LazyApp} />
            </EnhancedSwitch>
          </AuthProvider>
        </BrowserRouter>
      </Suspense>
    </Container>
  );
};

ReactDOM.render(<Index />, document.getElementById("root") as HTMLElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
