import React, { lazy, Suspense } from "react";
import { css } from "styled-components";
import { maxNumberOfApps, colors } from "@lovers14/common";
import { updateDoc, doc, collection } from "firebase/firestore";

import { AppLoading } from "./components/app/AppLoading";
import { domain, appName, profilesCollection, langs } from "./config";
import { db } from "./firebase";
import { UserI } from "./contexts/user";
import { Data } from "./hooks/useCreatorInitialValues";
import { defaultLang } from "./config";

interface CreateAppManifestProps {
  title: string;
  thumbnailSrc: string;
  id: string;
}
// TODO: specify icons url
export const createAppManifest = ({
  title,
  thumbnailSrc,
  id,
}: CreateAppManifestProps) => {
  const manifest = {
    short_name: title,
    name: title,
    icons: [
      {
        src: thumbnailSrc,
        sizes:
          "512x512 256x256 192x192 144x144 128x128 64x64 32x32 24x24 16x16",
        type: "image/png",
      },
    ],
    start_url: createAppUrl(id),
    display: "standalone",
    orientation: "portrait",
    theme_color: colors.secondary,
    background_color: colors.secondary,
    gcm_sender_id: process.env.REACT_APP_SENDER_ID,
  };
  return createBlobObjectURL(JSON.stringify(manifest));
};

export const createDefaultManifest = () => {
  const manifest = {
    short_name: appName,
    name: appName,
    icons: [
      {
        src: `${document.location.origin}/android-chrome-192x192.png`,
        sizes: "192x192",
        type: "image/png",
      },
      {
        src: `${document.location.origin}/android-chrome-512x512.png`,
        sizes: "512x512",
        type: "image/png",
      },
    ],
    start_url: `${document.location.origin}/`,
    display: "standalone",
    orientation: "portrait",
    theme_color: colors.secondary,
    background_color: colors.secondary,
    gcm_sender_id: process.env.REACT_APP_SENDER_ID,
  };
  return createBlobObjectURL(JSON.stringify(manifest));
};

export const createBlobObjectURL = (string: string) => {
  const blob = new Blob([string], { type: "application/json" });
  return URL.createObjectURL(blob);
};

interface Attribute {
  name: string;
  value: string;
}
interface SetTagPropertyProps {
  tag?: string;
  name: string;
  value: string;
  attributes: Attribute[];
}
export const setTagProperty = ({
  tag,
  name,
  value,
  attributes,
}: SetTagPropertyProps) => {
  let el: any = document.querySelector(`[${name}="${value}"]`);
  if (!el && tag) {
    el = document.createElement(tag);
    el.setAttribute(name, value);
    document.head.appendChild(el);
  }
  attributes.forEach(({ name: attrName, value: attrValue }) => {
    if (el) {
      el.setAttribute(attrName, attrValue);
    } else {
      console.log("el not found: ", name, value);
    }
  });
};

const sizes = {
  desktopUp: 992,
  tabletUp: 768,
  phoneUp: 576,
};

// Iterate through the sizes and create a media template
export const mediaSize = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args: any) => css`
    @media (min-width: ${(sizes as any)[label] / 16}em) {
      ${(css as any)(...args)};
    }
  `;
  return acc;
}, {} as any);

export const getRedirectTo = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get("redirectTo");
};

export const createLazyComponentWithSuspense = (name: string) => {
  const LazyComponent = lazy(() =>
    import(`./components/${name.toLowerCase()}/${name}`).then(
      ({ [name]: Component }) => ({
        default: Component,
      })
    )
  );
  return () => (
    <Suspense fallback={<AppLoading />}>
      <LazyComponent />
    </Suspense>
  );
};

export const isAllowedFreeUser = (user: UserI | null): boolean => {
  if (!user) return false;
  const { noa, paid } = user;
  // Assume these attributes are getting set after user is created the first time0
  if (noa === undefined && paid === undefined) {
    return true;
  }
  return !paid && noa === 0;
};

export const isAllowedPaidUser = (user: UserI | null): boolean => {
  if (!user) return false;
  const { noa, paid } = user;
  return paid === true && noa < maxNumberOfApps;
};

export const isPaidUser = (user: UserI | null) => {
  if (!user) return false;
  const { paid } = user;
  return Boolean(paid);
};

export const setUserLang = async (id: string, lang: string) => {
  try {
    await updateDoc(doc(collection(db, profilesCollection), id), {
      lang,
    });
  } catch (err) {
    console.log("setUserLang error", err);
  }
};

interface IResizeImageOptions {
  maxSize: number;
  file: File;
}
export const resizeImage = (settings: IResizeImageOptions): Promise<Blob> => {
  const file = settings.file;
  const maxSize = settings.maxSize;
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement("canvas");
  const dataURItoBlob = (dataURI: string) => {
    const bytes =
      dataURI.split(",")[0].indexOf("base64") >= 0
        ? atob(dataURI.split(",")[1])
        : unescape(dataURI.split(",")[1]);
    const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
  };
  const resize = () => {
    let width = image.width;
    let height = image.height;

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else {
      if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }
    }

    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext("2d");
    if (context) {
      context.drawImage(image, 0, 0, width, height);
      let dataUrl = canvas.toDataURL("image/png");
      return dataURItoBlob(dataUrl);
    } else {
      throw new Error("No canvas context");
    }
  };

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error("Not an image"));
      return;
    }

    reader.onload = (readerEvent: any) => {
      image.onload = () => ok(resize());
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
};

const options = {
  year: "numeric",
  month: "long",
  day: "numeric",
} as any;

export const formatDate = (date: Date, language: string) => {
  return new Intl.DateTimeFormat(language, options).format(date);
};

export const hasPaidFeatures = (values: Data, noa: number) => {
  return values.images.length > 1 || values.name || noa > 0;
};

// if its has the protocol  https://es.lovers14.com -> https://lovers14.com
const removeLangFromUrl = (url: string) => {
  const protocol = document.location.protocol;
  if (new RegExp(protocol).test(url)) {
    return url.replace(
      new RegExp(`^${protocol}//[a-z]{2}\\.`),
      `${protocol}//`
    );
  } else {
    return url.replace(/^[a-z]{2}\./, "");
  }
};

const getCurrentLang = () => {
  const subdomain = document.location.hostname.split(".")[0];
  return langs.find((lang) => lang === subdomain) || defaultLang;
};
export const currentLang = getCurrentLang();

const getOSLang = () => {
  return navigator.language.substring(0, 2);
};
export const osLang = getOSLang();

export const getLocalizedUrl = (
  to: string,
  url: string = document.location.href
) => {
  const protocol = document.location.protocol;
  const hrefWithoutLang = removeLangFromUrl(url);
  let newLocation = hrefWithoutLang;
  // Just remove the lang from the url and add the new one
  // if it's not de default
  if (to !== defaultLang) {
    newLocation = hrefWithoutLang.replace(
      new RegExp(`^${protocol}//`),
      `${protocol}//${to}.`
    );
  }
  return newLocation;
};

export const createAppUrl = (path?: string, _domain: string = domain) =>
  getLocalizedUrl(currentLang, `${_domain}/${path}`);

export const Cookie = {
  get: (name: string) => {
    const regexp = `(?:(?:^|.*; *)${name} *= *([^;]*).*$)|^.*$`;
    const match = document.cookie && document.cookie.match(regexp);
    if (match) {
      const c = match[1];
      if (c) {
        return decodeURIComponent(c);
      }
    }
  },
  set: (name: string, value: string, opts: any = {}) => {
    opts["max-age"] = opts["max-age"] || 365 * 24 * 60 * 60;
    opts["domain"] =
      opts["domain"] || `.${removeLangFromUrl(document.location.hostname)}`;
    opts["path"] = opts["path"] || "/";
    opts = Object.entries(opts).reduce(
      (str, [k, v]) => `${str}; ${k}=${v}`,
      ""
    );
    console.log("opts are", opts);
    document.cookie = name + "=" + encodeURIComponent(value) + opts;
  },
  delete: (name: any, opts: any = {}) =>
    Cookie.set(name, "", { "max-age": -1, ...opts }),
  // path & domain must match cookie being deleted
};

export const setHrefLangs = () => {
  langs.forEach((lang) => {
    const href = getLocalizedUrl(lang);
    setTagProperty({
      tag: "link",
      name: "rel",
      value: "alternate",
      attributes: [
        { name: "hreflang", value: lang },
        { name: "href", value: href },
      ],
    });
  });
};

// Avoid redirections for already redirected links
export const redirectTo = (localizedUrl: string) => {
  if (!/redirectTo/.test(localizedUrl)) {
    document.location.replace(localizedUrl);
  }
};

export const forcePNG = (name: string) => {
  return name.replace(/^(.+)(\..+)$/, "$1.png");
};

export const setSchema = () => {
  const script = document.createElement("script");
  script.setAttribute("type", "application/ld+json");
  script.innerHTML = JSON.stringify({
    "@context": "https://schema.org/",
    "@type": "Product",
    name: "lovers14 app",
    image: ["%REACT_APP_DOMAIN%/favicon-512x512.png"],
    description: "Customized app for your loved ones",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "5.0",
      reviewCount: "10",
    },
    offers: {
      "@type": "AggregateOffer",
      availability: "InStock",
      lowPrice: "0.00",
      highPrice: "4.99",
      priceCurrency: "EUR",
    },
  });
  document.head.appendChild(script);
};
