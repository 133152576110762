import React, { ReactNode } from "react";
import { ProfileI } from "@lovers14/common";

import { useAuth } from "../hooks/useAuth";

export type StatusI = boolean;

export interface UserI extends ProfileI {
  uid: string;
}

export const UserContext = React.createContext<[UserI | null, StatusI]>([
  null,
  true,
]);

export const AuthProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const auth = useAuth();
  return <UserContext.Provider value={auth}>{children}</UserContext.Provider>;
};
