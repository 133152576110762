export {
  appName,
  appsCollection,
  notificationsCollection,
  profilesCollection,
  appsNamesMapCollection,
  amount,
  currency,
  maxNumberOfImages,
} from "@lovers14/common";
export const price = 4.99;
export const country = "ES";
export const environment = process.env.REACT_APP_ENV;
export const isProduction = environment === "production";
export const isStaging = environment === "staging";
export const isDevelopment = environment === "development";
export const langs = ["en", "es"];
export const defaultLang = "en";
export const cookieLangKey = "lang";
export const cookieAddToHomeKey = "addToHome";
export const landingPaths = ["/", "/terms", "/privacy", "/legal"];
export const domain = isDevelopment
  ? `http://localhost:${window.location.port}`
  : (process.env.REACT_APP_DOMAIN as string);
export const portraitsBucketName =
  process.env.REACT_APP_STORAGE_PORTRAITS_BUCKET || "";
export const landscapesBucketName =
  process.env.REACT_APP_STORAGE_LANDSCAPES_BUCKET || "";
export const MAX_IMAGE_SIZE = 2000;
export const MAX_ICON_SIZE = 512;
export const iconsBucketName = process.env.REACT_APP_STORAGE_ICONS_BUCKET || "";
export const paymentIntentPath = "paymentIntent";
export const stripeKey = process.env.REACT_APP_STRIPE_KEY || "";
export const exampleImgName1 = process.env.REACT_APP_EXAMPLE_IMG_NAME_1;
export const exampleShortSubDomain1 =
  process.env.REACT_APP_EXAMPLE_SHORT_APP_NAME_1;
export const exampleLongSubDomain1 =
  process.env.REACT_APP_EXAMPLE_LONG_APP_NAME_1;
export const exampleImgName2 = process.env.REACT_APP_EXAMPLE_IMG_NAME_2;
export const exampleShortSubDomain2 =
  process.env.REACT_APP_EXAMPLE_SHORT_APP_NAME_2;
export const exampleLongSubDomain2 =
  process.env.REACT_APP_EXAMPLE_LONG_APP_NAME_2;
export const exampleImgName3 = process.env.REACT_APP_EXAMPLE_IMG_NAME_3;
export const exampleShortSubDomain3 =
  process.env.REACT_APP_EXAMPLE_SHORT_APP_NAME_3;
export const exampleLongSubDomain3 =
  process.env.REACT_APP_EXAMPLE_LONG_APP_NAME_3;
