import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

import {
  getLocalizedUrl,
  Cookie,
  currentLang,
  osLang,
  redirectTo,
} from "./utils";
import { langs, defaultLang, cookieLangKey } from "./config";

const loadLocales = async (url: string, options: any, callback: any) => {
  try {
    const locale = await import(`./locales/${url}.json`);
    callback(locale, { status: "200" });
  } catch (e) {
    callback(null, { status: "404" });
  }
};

const cookieLang = Cookie.get(cookieLangKey);

// If there is a saved language always redirect to it
if (cookieLang && currentLang !== cookieLang) {
  const localizedUrl = getLocalizedUrl(cookieLang);
  redirectTo(localizedUrl);

  // else redirect when OS language doesn't match web's language
} else if (!cookieLang && currentLang !== osLang && langs.includes(osLang)) {
  const localizedUrl = getLocalizedUrl(osLang);
  redirectTo(localizedUrl);
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: currentLang,
    whitelist: langs,
    fallbackLng: defaultLang,
    interpolation: {
      escapeValue: false,
    },
    defaultNS: "index",
    ns: ["index", "legal"],
    backend: {
      loadPath: `{{lng}}/{{ns}}`,
      parse: (data: any) => data,
      ajax: loadLocales,
    },
  });
