import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { doc, collection, onSnapshot } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

import { auth, db } from "../firebase";
import { profilesCollection } from "../config";
import { setUserLang, currentLang } from "../utils";
import { UserI, StatusI } from "../contexts/user";

export const useAuth = (): [UserI, StatusI] => {
  const [user, setUser] = useState<any>(null);
  const [userWithProfile, setUserWithProfile] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();

  useEffect(() => {
    console.log("authstate changed set");
    const unsubscribe = onAuthStateChanged(auth, (user: any) => {
      if (user) {
        setUser({
          email: user.email,
          uid: user.uid,
        });
      } else {
        setUser(null);
        setUserWithProfile(null);
        setLoading(false);
      }
    });
    return unsubscribe;
  }, []);

  // Profile data
  useEffect(() => {
    if (user) {
      const userId = user.uid;
      const profile = doc(collection(db, profilesCollection), userId);
      const unsubscribe = onSnapshot(profile, (profileSnapshot: any) => {
        const profileData = profileSnapshot.data();
        const { noa, paid, lang } = profileData || {};
        setUserWithProfile({
          email: user.email,
          uid: userId,
          noa,
          paid,
          lang,
        });

        setLoading(false);
      });
      return unsubscribe;
    }
  }, [user]);

  // Lang data
  useEffect(() => {
    // Only run when cloud function has initialized the profile
    if (
      userWithProfile &&
      userWithProfile.noa !== undefined &&
      userWithProfile.paid !== undefined
    ) {
      if (userWithProfile.lang !== currentLang) {
        setUserLang(userWithProfile.uid, currentLang);
      }
    }
  }, [i18n.languages, userWithProfile]);

  return [userWithProfile, loading];
};
