import { useLayoutEffect } from "react";
import { useLocation } from "react-router";

import { usePrevious } from "./usePrevious";

export const useScrollToTop = () => {
  const { pathname } = useLocation();
  const previousPathname = usePrevious(pathname);
  useLayoutEffect(() => {
    if (previousPathname !== pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname, previousPathname]);
  return null;
};
